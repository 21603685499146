<template>
	<div>
		<el-checkbox-group v-model="select_product_ids" @change="bindCheck">
		<ul class="product_list" :class="listClass">
				<li class="product" v-for="(item,index) in listData" :key="index">
					<el-checkbox class="select-btn" :label="item.product_id"></el-checkbox>
					<div class="img_box" @click="openDetail('./detail?id='+item.product_id)" >
						<!-- <img :src="item.img" /> -->
						<el-image :src="item.img" fit="cover" lazy></el-image>
					</div>
					<div class="con_box">
						<a class="title" @click="openDetail('./detail?id='+item.product_id)">{{item.name}}</a>
						<div class="price_line">
							<p class="price">￥<em>{{item.discount_price}}</em></p>
						</div>
						<div class="info_line">
              <!-- <p class="supplier" @click.stop="gotoSupplier(item.supplier.id)">
                <i class="el-icon-s-shop"></i> {{item.supplier.nickname}}
              </p> -->
			  <p class="supplier">
								<i class="el-icon-time"></i> {{item.public_time}}
							</p>
							<!-- <p class="sales">已售 {{item.sales_num}}</p> -->
							<p class="sales">浏览量 {{item.source_view_num}}</p>
						</div>
						<!-- <div class="info_line">
							<p class="supplier">
								<i class="el-icon-time"></i> {{item.public_time}}
							</p>
						</div> -->
					</div>
					
					<div class="btn_wrap" v-if="item.ddproduct_count>=1">
						<p class="btn" @click="bindSelect(item.product_id)">点击选取</p>
						<p class="btn active" @click="callPh(item.product_id)">已铺货</p>
					</div>
					<div class="btn_wrap" v-else>
						<p class="btn" @click="bindSelect(item.product_id)">点击选取</p>
						<p class="btn" @click="callPh(item.product_id)">立即铺货</p>
					</div>
				</li>
			<li v-if="listData.length==0 && !loading" style="margin: 0 auto;">
				<el-empty description="暂无任何商品"></el-empty>
			</li>
			<li v-if="loading" style="margin: 0 auto;display: flex;flex-direction: column;align-items: center;color: #999;margin-top: 20px;">
				<div><i style="font-size: 30px;" class="el-icon-loading"></i></div>
				<div style="font-size: 13px;margin-top: 10px;">加载中...</div>
			</li>
		</ul>
		<div :class="is_xq?'xq_mask active' : 'xq_mask'" @click.self="is_xq = false">
			<div class="xq_main" v-loading="isLoading">
				<i class="iconfont icon-guanbi" @click.self="is_xq = false"></i>
				<p><i class="iconfont el-icon-loading" v-if="isLoading"></i>{{isLoading?`正在添加`:`添加成功`}}</p>
				<p class="ok_add" v-if="!isLoading">
					<i class="iconfont icon-gouxuan"></i>货品已添加至选取列表
				</p>
				<p class="sel_txt" v-if="!isLoading">当前选取列表共<em>{{cart_num}}</em>种产品</p>
				<p class="links" v-if="!isLoading">
					<a href="javascript:;" @click="$router.push('./cart')">去铺货</a>
					<a href="#" @click="is_xq=false">继续购物</a>
				</p>
				<p class="swiper_title" v-if="!isLoading && products.length>0">您可能也感兴趣：</p>
				<div class="xq_swiper" v-if="!isLoading && products.length>0">
					<div class="swiper">
						<ul class="swiper-wrapper">
							<li class="swiper-slide" v-for="(item,index) in products" :key="index">
								<a :href="'./detail?id='+item.product_id" class="product_box">
									<div class="img_box"><img class="coverimg" :src="item.img" /></div>
									<p class="title">{{item.name}}</p>
									<p class="price"><i>￥</i>{{item.discount_price}}</p>
								</a>
							</li>
						</ul>
					</div>
					<div class="swiper_btn prev"><i class="iconfont icon-fanhui"></i></div>
					<div class="swiper_btn next"><i class="iconfont icon-fanhui"></i></div>
				</div>
			</div>
		</div>
		</el-checkbox-group>
		<shopList ref="child"></shopList>
	</div>
</template>

<script>
import Vue from 'vue';
import "swiper/swiper-bundle.min.css";
import Swiper, { Navigation } from "swiper";
import { mapActions,mapState,mapMutations } from "vuex";
import shopList from '../../components/shopList/shopList';
export default {
	name: "productList",
	props:{
		listData:{
			type:Array,
			default:[]
		},
		loading: {
			type: Boolean,
		},
    listClass: {
			type: String,
      default: "five"
		},
	},
	components:{
		shopList
	},
	data() {
		return {
			is_xq:false,
			is_ph:false,
			isLoading:false,
			ddShopList:[],
			product_id:'',
			products: [],
			cart_num: 0,
			select_product_ids: [],
      userinfo:""
		};
	},
  computed:{
    ...mapState({
      users:(state)=>state.login.userinfo
    })
  },
	mounted(){
		this.initSwiper()
	},
	methods:{
		...mapActions({
			getDdShopList:"ucenter/getDdShopList",
			doProductSelect:"index/doProductSelect",
			doSubmitPH:"cart/doSubmitPH",
		}),
		initSwiper(){
			this.$nextTick(function(){
				new Swiper('.xq_swiper .swiper', {
					slidesPerView: 4,
					spaceBetween: 10,
					loopedSlides: 1,
					modules: [Navigation],
					navigation: {
						nextEl: ".xq_swiper .swiper_btn.next",
						prevEl: ".xq_swiper .swiper_btn.prev",
					},
					loop: true,
				})
			})
		},
		openDetail(routePath){
			const fullPath = this.$router.resolve(routePath).href;  
			window.open(fullPath, '_blank');  
		},
		callPh(id){//打开子组件店铺列表
			this.$refs.child.showShopList()
			this.product_id = id
		},
		phSubmit(val,strategy){//立即铺货
			let param = {shop_id:val,type:'1',product_id:this.product_id,strategy:strategy}
			this.doSubmitPH({data:param,success:(res)=>{
				if(res.code===200){
					this.$message({message: res.msg,type: 'success',});
					setTimeout(()=>{
						this.$refs.child.closeShopList()
					},300)
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		bindCheck(e){
			console.log(e)
			
		},
		checkAll(){
			if(this.select_product_ids.length!=0){
				this.select_product_ids = []
				return
			}
			let list = this.listData
			let select_ids = []
			for(var i in list){
				select_ids.push(list[i].product_id)
			}
			this.select_product_ids = select_ids
		},
		bindSelect(id){//商品选取
			this.isLoading = true
			let param = {product_id:id,product_sku_id:""}
			// console.log(param)
			this.doProductSelect({data:param,success:(res)=>{
				if(res.code===200){
					this.is_xq = true
					this.products = res.data.products
					this.cart_num = res.data.cart_num
					
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.isLoading = false
			}})
		},
    gotoSupplier(id){
      // this.userinfo = this.users?this.users:""
      // if(this.userinfo===""){
      //   this.$emit('login')
      // }else{
        this.$router.push(`/supplier?id=${id}`)
      // }

    },
	}
}
</script>

<style scoped>
.xq_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.xq_mask.active{visibility: visible;opacity: 1;}
.xq_mask .xq_main{width: 530px;height: auto;box-sizing: border-box;padding: 24px 20px 40px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.xq_mask.active .xq_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.xq_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.xq_main .ok_add{margin: 30px 0 0 38px;display: flex;align-items: center;}
.xq_main .ok_add .icon-gouxuan{font-size: 26px;color: #0e9f1f;margin-right: 9px;}
.xq_main .sel_txt{margin: 12px 0 0 72px;font-size: 12px;color: #999;}
.xq_main .sel_txt>em{font-weight: bold;color: #f0423f;}
.xq_main .links{margin: 18px 0 0 72px;font-size: 12px;}
.xq_main .links>a{color: #f0423f;margin-right: 8px;}
.xq_main .swiper_title{margin: 30px 0 0 16px;}
.xq_main .xq_swiper{margin: 16px 16px 0;position: relative;}
.xq_main .xq_swiper .swiper{margin: 0 26px;}
.xq_main .xq_swiper .swiper .product_box{width: 100%;height: auto;}
.xq_main .xq_swiper .swiper .product_box .img_box{width: 90px;height: 90px;cursor: pointer;}
.xq_main .xq_swiper .swiper .product_box .title{font-size: 12px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;line-height: 1;margin-top: 8px;color: #333333;}
.xq_main .xq_swiper .swiper .product_box .price{margin-top: 12px;color: #f0423f;}
.xq_main .xq_swiper .swiper .product_box .price>i{font-size: 12px;}
.xq_main .xq_swiper .swiper_btn{width: 26px;height: 20px;display: flex;justify-content: center;align-items: center;cursor: pointer;position: absolute;top: 36px;z-index: 22;}
.xq_main .xq_swiper .swiper_btn.prev{left: 0;}
.xq_main .xq_swiper .swiper_btn.next{right: 0;}
.xq_main .xq_swiper .swiper_btn .icon-fanhui{font-size: 20px;color: #999;}
.xq_main .xq_swiper .swiper_btn:hover .icon-fanhui{color: #fa5757;}
.xq_main .xq_swiper .swiper_btn.next .icon-fanhui{transform: rotate(180deg);}



.product_list{padding-top: 5px;display: flex;flex-wrap: wrap;}
.product_list .product{width: 220px;height: auto;box-sizing: border-box;border: 1px solid #eeeeee;margin: 15px 15px 0 0;position: relative;}
.product_list .product .select-btn{position: absolute;left: 10px;top: 10px;}
.product_list.four .product:nth-child(4n){margin-right: 0;}
.product_list.five .product:nth-child(5n){margin-right: 0;}
.product .img_box{width: 100%;height: 220px;overflow: hidden;cursor: pointer;}
.product .img_box img{transition: opacity .5s;}
.product .img_box:hover img{opacity: .4;}
.product .con_box{padding: 10px 10px 15px;}
.product .con_box .title{height: 40px;font-size: 14px;line-height: 20px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;color: #333333;cursor: pointer;}
.product .con_box .title:hover{color: #fa5757;text-decoration: underline;}
.product .con_box .price_line{margin-top: 15px;display: flex;justify-content: space-between;line-height: 1;align-items: flex-end;}
.product .con_box .price_line .price{color: #f0423f;}
.product .con_box .price_line .price>em{font-size: 24px;}
.product .con_box .info_line{margin-top: 12px;display: flex;justify-content: space-between;line-height: 1;}
.product .con_box .info_line .sales{font-size: 12px;color: #999;width: 50%;text-overflow:ellipsis;white-space: nowrap;text-align: right;align-items: flex-end;}
.product .con_box .info_line .supplier{font-size: 12px;color: #999;width: 50%;text-overflow:ellipsis;white-space: nowrap;}
.product .con_box .info_line .supplier:hover{
  color: #fa5757;
  text-decoration: underline;
  cursor: pointer;
}
.product .btn_wrap{border-top: 1px solid #eeeeee;display: flex;}
.product .btn_wrap .btn{flex: 1;height: 34px;display: flex;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #eeeeee;font-size: 14px;color: #666666;cursor: pointer;}
.product .btn_wrap .btn:last-child{border-right: none;}
.product .btn_wrap .btn:hover{color: #fa5757;}
.product .btn_wrap .btn.active{color: #fa5757;}
</style>
<style>
.ph_main .ph_sel .el-checkbox__label{display: none !important;}
</style>