<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body" v-loading="isLoading">
			<div class="center">
				<div class="page_links">
					当前位置：
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/uc_order' }">订单管理</el-breadcrumb-item>
						<el-breadcrumb-item>订单详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="order_status">
					<div class="status_wrap">
						<p class="txt">当前订单状态</p>
						<p class="status">{{detail.order_status_text}}</p>
					</div>
					<div class="detail_wrap">
						<el-descriptions labelClassName="la_style" contentClassName="con_style" :colon="false">
							<el-descriptions-item label="订单号">{{detail.dd_order_id?detail.dd_order_id:detail.order_no}}</el-descriptions-item>
							<el-descriptions-item label="下单时间">{{detail.create_time}}</el-descriptions-item>
							<el-descriptions-item label="支付类型">{{detail.pay_type_text}}</el-descriptions-item>
							<el-descriptions-item label="付款时间">
								{{detail.pay_time!='1970-01-01 08:00:00'?detail.pay_time:'-'}}
							</el-descriptions-item>
							<el-descriptions-item label="完成时间">
								{{detail.finish_time!='1970-01-01 08:00:00'?detail.finish_time:'-'}}
							</el-descriptions-item>
							<el-descriptions-item></el-descriptions-item>
							<el-descriptions-item label="商家备注">{{detail.seller_words}}</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<div class="order_infos">
					<div class="info_con" v-if="detail.order_address">
						<el-descriptions title="收货信息" labelClassName="la_style" contentClassName="con_style" :colon="false" :column=1 >
							<el-descriptions-item label="收货人">
							{{detail.order_address.mask_name}}
							<el-tooltip class="item" effect="dark" content="点击查看" placement="top">
								<i class="iconfont el-icon-view" @click="showAddressInfo()"></i>
							</el-tooltip>
							</el-descriptions-item>
							<el-descriptions-item label="手机号码">{{detail.order_address.mask_phone}}</el-descriptions-item>
							<el-descriptions-item label="收货地址">{{detail.order_address.province}}{{detail.order_address.city}}{{detail.order_address.town}}{{detail.order_address.mask_detail}}</el-descriptions-item>
						</el-descriptions>
					</div>
					<div class="info_con" v-if="detail && detail.supplier_order_delivery.length>0">
						<el-descriptions title="物流信息" labelClassName="la_style" contentClassName="con_style" :colon="false" :column="1">
							<template slot="extra">
								<div class="pack_btn">
									<el-button size="mini" type="info" plain v-for="(delivery,i) in detail.supplier_order_delivery" :key="i" @click="deliveryChange(i)">包裹{{i+1}}</el-button>
								</div>
							</template>
							<template v-for="(d,i) in detail.supplier_order_delivery" v-if="delivery_idx == i">
								<el-descriptions-item label="快递公司">{{d.company}}</el-descriptions-item>
								<el-descriptions-item label="物流单号">{{d.logistics_code}}</el-descriptions-item>
								<el-descriptions-item label="发货时间">{{d.update_time}}</el-descriptions-item>
								<el-descriptions-item label="商品信息">
									<div class="goods" v-for="(goods,idx) in d.supplier_order_delivery_product">
										<!-- <el-image class="cover_image" fit="cover" :src="goods.product_pic"></el-image> -->
										<div class="">{{goods.product_name}}</div>
										<div class="">{{goods.sku_specs}} x{{goods.product_count}}</div>
									</div>
								</el-descriptions-item>
							</template>	
						</el-descriptions>
					</div>
					<div class="info_con" v-if="detail && detail.supplier_order_delivery.length==0&&detail.dd_order_print">
						<el-descriptions title="物流信息" labelClassName="la_style" contentClassName="con_style" :colon="false" :column="1">
							<template slot="extra">
								<div class="pack_btn">
									<el-button size="mini" type="info" plain >包裹1</el-button>
								</div>
							</template>
							<template >
								<el-descriptions-item label="快递公司">{{detail.dd_order_print.logistics_company}}</el-descriptions-item>
								<el-descriptions-item label="物流单号">{{detail.dd_order_print.track_no}}</el-descriptions-item>
							</template>	
						</el-descriptions>
					</div>
				</div>
				<!-- <div class="order_infos">
					<div class="info_con">
						<el-descriptions title="退货信息" labelClassName="la_style" contentClassName="con_style" 
						:colon="false">
						
							<el-descriptions-item label="快递公司">圆通快递</el-descriptions-item>
							<el-descriptions-item label="快递单号">4962990566310610271</el-descriptions-item>
							<el-descriptions-item label="退货地址">福建省 泉州市 洛江区  福建省泉州市洛江区马甲镇***</el-descriptions-item>
						</el-descriptions>
					</div>
				</div> -->
				<div class="order_goods">
					<p class="fb">商品信息</p>
					<div class="table_wrap">
						<el-table :data="detail.order_product">
							<el-table-column label="货品信息" width="" prop="scope">
								<template slot-scope="scope">
									<div class="goods_wrap">
										<div class="img_box"><img class="coverimg" :src="scope.row.product_pic" /></div>
										<div class="con_box">
											<p class="name">{{scope.row.product_name}}</p>
											<p class="color">{{scope.row.spec_value}}</p>
											<!-- <p class="bianma">供应商：{{scope.row.supplier.nickname}}</p> -->
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="售价/数量" prop="scope" width="140" class="c_price">
								<template slot-scope="scope">
									<p>{{scope.row.item_amount}}</p>
									<p class="num">x{{scope.row.item_num}}</p>
								</template>
							</el-table-column>
							<el-table-column label="售后状态" prop="scope" width="150">
								<template slot-scope="scope">
									<p>{{scope.row.order_status_text}}</p>
									<p class="red" @click="showAfterSaleInfo(scope.row)" v-if="scope.row.order_after_sales">{{scope.row.order_after_sales.text_part.aftersale_status_text}}</p>
								</template>
							</el-table-column>
							<el-table-column label="采购成本" prop="order_amount" width="200">
								<template slot-scope="scope">
									<p>{{scope.row.total_cost_price}}</p>
									<p class="red" v-if="scope.row.deduction_money>0">抵扣金抵扣：{{scope.row.deduction_money}}</p>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="total_txt">
						<p>运费: ￥{{detail.freight_price}}，<span> 合计:<em>￥{{detail.total_amount}}</em></span></p>
						<p>(实付:￥{{detail.pay_amount}}， 支付优惠:￥{{detail.promotion_amount}})</p>
					</div>
				</div>
			
			</div>
		</div>
		<!-- 收货地址信息弹窗 -->
		<orderAddress @close="dialog.addressDialog = false" :isLoading.sync="isLoading" :show.sync="dialog.addressDialog" :order_id.sync="order_id"></orderAddress>
		<!-- 售后详情弹窗 -->
		<el-dialog title="售后详情" :visible.sync="dialog.aftersaleDialog" width="60%" center >
			<orderAfterSales @close="dialog.aftersaleDialog = false" @complete="init()" :isLoading.sync="isLoading" :show.sync="dialog.aftersaleDialog" :info.sync="order_product"></orderAfterSales>
		</el-dialog>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import orderAddress from '../../components/orderAddress/orderAddress';
import orderAfterSales from '../../components/orderAfterSales/orderAfterSales';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "orderDetail",
	data() {
		return {
			order_id:0,
			detail:'',
			dialog: {
				addressDialog:false,
				aftersaleDialog:false
			},
			isLoading:false,
			delivery_idx: 0,
			order_product:'',
			
		}
	},
	components:{
		myHead,myFoot,orderAddress,orderAfterSales
	},
	created(){
		this.order_id = parseInt(this.$route.query.order_id)
		this.init()
	},
	methods: {
		...mapActions({
			getOrderManageDetail:"ucenter/getOrderManageDetail",
		}),
		init(){
			var param = {order_id:this.order_id}
			this.isLoading = true
			this.getOrderManageDetail({data:param,success:(res)=>{
				if(res.code == 200){
					this.detail = res.data
				
				}else{
					this.$message({message: res.msg,type: 'error'});
				}
				this.isLoading = false
			}})
			
		},
		showAddressInfo(){
			this.dialog.addressDialog = true
		},
		deliveryChange(index){
			this.delivery_idx = index 
		},
		showAfterSaleInfo(order_product){
			this.order_product = order_product
			this.dialog.aftersaleDialog = true
		},
	}
}
</script>

<style scoped>
.order_status{margin-top: 30px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px 20px;background: #fff;}
.order_status .status_wrap{line-height: 1;padding-bottom: 30px;border-bottom: 1px solid #eee;}
.order_status .status_wrap .txt{font-size: 14px;color: #999999;}
.order_status .status_wrap .status{font-size: 24px;margin-top: 16px;}
.order_status .detail_wrap{padding-top: 30px;}
.order_infos{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 0;background: #fff;display: flex;}
.order_infos .info_con{flex: 1;margin-left: 40px;width: 0;box-sizing: border-box;border-right: 1px solid #eee;}
.order_infos .info_con:last-child{border-right: none;}
.order_infos .info_con .goods{position: relative;width: 300px;height: auto;display: inline-block;margin-right: 5px;}
.order_infos .info_con .goods .cover_image{width:60px;height:60px;border: 1px solid #f5f5f5;}
.order_infos .info_con .goods .num{font-size: 12px;position: absolute;bottom:0px;right: 0px;color: #333;background-color: rgba(255,255,255,0.8);padding: 2px 4px;}
.order_infos .info_con .pack_btn{margin-right: 20px;}
.order_goods{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.order_goods .table_wrap{margin-top: 30px;font-size: 12px;}
.goods_wrap{width: 100%;box-sizing: border-box;display: flex;}
.goods_wrap .img_box{width: 48px;height: 48px;overflow: hidden;background: #eee;}
.goods_wrap .img_box>img{width: 100%;height: 100%;object-fit: cover;}
.goods_wrap .con_box{max-width: 80%;margin-left: 10px;text-align: left;}
.goods_wrap .con_box .name{line-height: 16px;color: #333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.goods_wrap .con_box .color{color: #999999;margin-top: 10px;line-height: 1;}
.goods_wrap .con_box .bianma{color: #999999;margin-top: 6px;line-height: 1;}
.order_goods .total_txt{display: flex;justify-content: flex-end;flex-direction: column;font-size: 12px;color: #999999;align-items: flex-end;padding: 26px 0 15px;}
.order_goods .total_txt span{font-size: 16px;color: #333;}
.order_goods .total_txt span>em{color: var(--red);font-weight: bold;}
.order_goods .total_txt>p:nth-child(2){margin-top: 10px;}
.red{color: var(--red);cursor: pointer;}

.order_records{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.records_wrap{margin-top: 25px;}
.records_wrap .record_con{padding-left: 42px;position: relative;}
.record_con::before{width: 32px;height: 32px;border-radius: 50%;display: flex;justify-content: center;align-items: center;position: absolute;top: 0;left: 0;z-index: 2;font-size: 12px;font-weight: bold}
.record_con.c{padding-bottom: 13px;}
.record_con.c::before{content: '买家';background: #ffecee;color: var(--red);}
.record_con.b::before{content: '商家';background: #f0ffee;color: #0e9f1f;}
.record_con.c::after{content: '';width: 2px;height: 68%;background: #eee;position: absolute;left: 15px;top: 40px;}
.record_con .txt{font-size: 14px;line-height: 32px;}
.record_con .txt_wrap{margin-top: 12px;width: 900px;}
</style>
<style>
.page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
.page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
.page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
.page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}
.el-table .el-table_1_column_2 .cell .num{color: #999;}
.el-table th.el-table__cell{background: #fff6f7;border-bottom: none !important;color: #666666;padding: 12px 0;}
.el-table .el-table__cell{vertical-align: top;padding: 24px 0;}
.el-table .cell{padding: 0 20px;text-align: center;font-size: 12px;color: #333;}
.el-table th.el-table__cell>.cell{padding: 0 20px;}
.el-table .el-table_1_column_1 .cell{text-align: left;}
.el-table .el-table_1_column_2 .cell{text-align: right;}
.el-table th.el-table_1_column_1 .cell{text-align: left;}
.el-table th.el-table_1_column_2 .cell{text-align: right;}
.con_style{color: #333;}
.la_style{color: #999;width: 70px;}
.el-descriptions{font-size: 12px;}
.el-descriptions__title{color: #333;}
.el-icon-view{cursor: pointer;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);background-color: #fff;}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

</style>