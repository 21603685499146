import {
	mallCateData,
	mallProductList,
	searchProductByImage,
	businessOrderCount,
	productSelect,
	productDetail,
	indexBannerData,
	announcementData,
	announcementDetail,
	singlePageData,
	singlePageDetail,
	supplierCate,
	collectSupplier,
	dangkouList,
	shopList
} from "../../../api/main/index.js";
let modules = {
	namespaced: true,
	state: {


	},
	mutations: {
		
	},
	actions: {
		getShopList(conText, payload){
			shopList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getMallCateData(conText, payload){//商品分类
			mallCateData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getMallProductList(conText, payload){//商品列表
			mallProductList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doSearchProductByImage(conText, payload){//图搜
			searchProductByImage(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getBusinessOrderCount(conText, payload){//获取商家订单数量
			businessOrderCount(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doProductSelect(conText, payload){//商品选取
			productSelect(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getProductDetail(conText, payload){//商品详情
			productDetail(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getIndexBannerData(conText, payload){//首页banner，广告
			indexBannerData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getAnnouncementData(conText, payload){//获取公告列表
			announcementData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getAnnouncementDetail(conText, payload){//获取公详情
			announcementDetail(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getSinglePageData(conText, payload){//获取单页列表
			singlePageData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getSinglePageDetail(conText, payload){//获取单页详情
			singlePageDetail(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getSupplierCate(conText, payload) {//供应商商品分类
			supplierCate(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doCollectSup(conText, payload){//商品选取
			collectSupplier(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getDangkouList(conText, payload){//获取档口列表
			dangkouList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		}
	}

}
export default modules;
