<template>
    <el-dialog
        title="应用订购到期或即将到期的店铺"
        :visible.sync="visible"
        width="1000px"
        @close="closeDialog"
        :close-on-click-modal="false"
    >
        <div class="warning-text">
            到期影响订单获取、打单发货、铺货上架功能，请及时处理。订购后选择【立即使用】或【重新授权】后生效
        </div>
        <el-table 
            :data="tableData" 
            style="width: 100%"
            border
            :cell-style="{ padding: '8px 4px' }"
            :header-cell-style="{ background: '#fafafa' }"
        >
            <el-table-column
                prop="shopName"
                label="店铺名称"
                width="310"
                show-overflow-tooltip
            />
            <el-table-column
                prop="appName"
                label="应用名称"
                width="210"
                show-overflow-tooltip
            />
            <el-table-column
                prop="expireTime"
                label="到期时间"
                width="160"
                show-overflow-tooltip
            />
            <el-table-column
                prop="remainTime"
                label="剩余时间"
                width="120"
                show-overflow-tooltip
            />
            <el-table-column
                label="操作"
                min-width="80"
                align="center"
            >
                <template slot-scope="scope">
                    <el-button
                        type="danger"
                        size="small"
                        @click="handleAction(scope.row)"
                    >
                        去订购
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
export default {
    name: 'ExpiredDialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        tableData: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close');
        },
        handleAction(item) {
            if (item.url) {
                window.location.href = item.url;
            }
        }
    }
}
</script>

<style scoped>
.warning-text {
    color: #ff4d4f;
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 12px;
}

/* 覆盖 element-ui 的一些默认样式 */
:deep(.el-dialog__body) {
    padding: 20px;
}

:deep(.el-table .cell) {
    white-space: nowrap;
}

:deep(.el-table) {
    margin-bottom: 20px;
}

:deep(.el-table td), 
:deep(.el-table th) {
    text-align: center;
}

:deep(.el-table--border th), 
:deep(.el-table--border td) {
    border-right: 1px solid #EBEEF5;
}

:deep(.el-table::before) {
    height: 1px;
    background-color: #EBEEF5;
}

/* 覆盖 element-ui 的按钮颜色 */
:deep(.el-button--danger) {
    background-color: #f0413e;
    border-color: #f0413e;
}

:deep(.el-button--danger:hover),
:deep(.el-button--danger:focus) {
    background-color: #f46461;
    border-color: #f46461;
}
</style>