import config from "../../assets/js/conf/config";
import request from "../../assets/js/utils/request";

/**
 * 首页
 */
export function mallCateData(data){//获取首页分类
	return request.get(config.baseApi+"/business/Mall/cateData",data)
}
export function shopList(data){//获取首页是否需要展示弹框提示
	return request.get(config.baseApi+"/business/DdShop/get_expire_list",data)
}
export function mallProductList(data){//获取首页商品列表
	return request.get(config.baseApi+"/business/Mall/productList",data)
}
export function searchProductByImage(data){//图片搜索商品
	return request.post(config.baseApi+"/business/Mall/searchProductByImage",data)
}
export function businessOrderCount(data){//获取商家订单数量
	return request.get(config.baseApi+"/business/Mall/businessOrderCount",data)
}
export function productSelect(data){//商品选取
	return request.post(config.baseApi+"/business/Cart/add",data)
}
export function indexBannerData(data){//首页banner，广告
	return request.get(config.baseApi+"/business/Advert/index",data)
}
export function announcementData(data){//获取公告列表
	return request.get(config.baseApi+"/business/Announcement/list",data)
}
export function announcementDetail(data){//获取公告详情
	return request.get(config.baseApi+"/business/Announcement/detail",data)
}
export function singlePageData(data){//获取单页列表
	return request.get(config.baseApi+"/business/Page/list",data)
}
export function singlePageDetail(data){//获取单页详情
	return request.get(config.baseApi+"/business/Page/detail",data)
}


/**
 * 商品详情
 */
export function productDetail(data){//获取商品详情
	return request.get(config.baseApi+"/business/Mall/productDetail",data)
}

export function supplierCate(data){//获取供应商商品分类
	return request.get(config.baseApi+"/business/Supplier/index",data)
}

export function collectSupplier(data){//收藏档口
	return request.post(config.baseApi+"/business/DdProduct/collect_supplier",data)
}

export function dangkouList(data){//获取档口列表
	return request.get(config.baseApi+"/business/Mall/dangkouList",data)
}