<template>
  <div class="wrap">
    <myHead></myHead>
    <div class="wrap_body" v-loading="!is_show">
      <div class="top_content">
        <div class="center">
          <div class="page_links">
            当前位置：
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>产品详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="detail_content">
            <div class="left_con">
              <div class="big_show">
                <div class="swiper">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
                      <div class="img_box"><img :src="item"/></div>
                    </li>
                  </ul>
                </div>
                <div class="sl_btn prev"><i class="iconfont icon-fanhui"></i></div>
                <div class="sl_btn next"><i class="iconfont icon-fanhui"></i></div>
              </div>
              <div class="small_list">
                <div class="swiper">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
                      <div class="img_box"><img :src="item"/></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="right_con">
              <p class="title">{{ productData.name }}</p>
              <p class="price">￥<em>{{ showPrice }}</em><span v-if="showNum!==''">（库存 {{ showNum }}）</span></p>
              <div class="border_content">
                <div class="txt_line" v-if="false">
                  <p class="txt">运费</p>
                  <p class="con">{{ productData.freight_text }}</p>
                </div>
                <div class="txt_line" v-if="false">
                  <p class="txt">服务</p>
                  <p class="con curp">
                    <el-dropdown trigger="click" placement="bottom-start">
											<span class="el-dropdown-link">
											48小时发货 ▪ 7天无理由退货<i class="el-icon-arrow-down el-icon--right"></i>
											</span>
                      <el-dropdown-menu slot="dropdown" class="service">
                        <div class="service_hide">
                          <p class="title">基础服务</p>
                          <div class="txt_con">
                            <i class="iconfont icon-gouxuan"></i>
                            <div class="con">
                              <p class="tit">48小时发货</p>
                              <p class="txt">
                                该商家已开通48小时发货延必赔服务，承诺在支付成功起48小时内为您发货，如商家未能如约发货，您可在此笔订单中申请违约赔付。</p>
                            </div>
                          </div>
                          <div class="txt_con">
                            <i class="iconfont icon-gouxuan"></i>
                            <div class="con">
                              <p class="tit">7天无理由退货</p>
                              <p class="txt">
                                该商家已开通48小时发货延必赔服务，承诺在支付成功起48小时内为您发货，如商家未能如约发货，您可在此笔订单中申请违约赔付。</p>
                            </div>
                          </div>
                          <div class="txt_con">
                            <i class="iconfont icon-gouxuan"></i>
                            <div class="con">
                              <p class="tit">15天无理由退货</p>
                              <p class="txt">
                                该商家已开通48小时发货延必赔服务，承诺在支付成功起48小时内为您发货，如商家未能如约发货，您可在此笔订单中申请违约赔付。</p>
                            </div>
                          </div>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </p>
                </div>
                <div class="txt_line">
                  <p class="txt">铺货</p>
                  <div class="con">
                    支持以下渠道
                    <!-- <img class="icon" src="../../assets/images/icon_tb.png" />淘宝 -->
                    <img class="icon" src="../../assets/images/icon_dy.png"/>抖音
                    <!-- <img class="icon" src="../../assets/images/icon_ks.png" />快手 -->
                  </div>
                </div>
                <!-- <div class="txt_line">
                  <p class="txt">供应商</p>
                  <div class="con supplier-name" v-if="productData.supplier"
                       @click.stop="gotoSupplier(productData.supplier.id)">{{ productData.supplier.nickname }}
                  </div>
                </div> -->
              </div>
              <div class="border_content flow auto_scrollbar">
                <div class="config_wrap" v-for="(item,index) in cateList" :key="index">
                  <p class="txt">{{ item.group_name }}</p>
                  <div class="con">
                    <p class="config_box" :class="item2.is_select?'active':''" v-for="(item2,index2) in item.spec_items"
                       :key="index2" @click="configTab(index,index2)">
                      <img v-if="item2.icons" class="img" :src="item2.icons"/>
                      {{ item2.spec_value }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="btn_wrap">
                <p class="btn bgred" @click="buyNow()">立即购买</p>
                <p class="btn bgwhite" @click="bindSelect()">加进货车</p>
                <p class="btn bgwhite" @click="callPh()">立即铺货</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main_content">
        <div class="center d-f">
          <template v-if="list.length">
            <div class="guess_like mr_15">
              <p class="title">推荐商品</p>
              <productList :listData="list" @login="gotoSupplier()"></productList>
            </div>
          </template>

          <div class="goods_info" style="flex: 1">
            <div class="tabs_wrap">
              <p :class="infoType==2?'tab active':'tab'" @click="infoType=2">商品详情</p>
              <p :class="infoType==1?'tab active':'tab'" @click="infoType=1">商品属性</p>
            </div>
            <div class="info_wrap" v-show="infoType==1">
              <div class="info_line" v-for="(format,index) in formatList" :key="index">
                <p>{{ format.PropertyName }}</p>
                <p>{{ format.Name }}</p>
              </div>
            </div>
            <div class="rich_txt" v-show="infoType==2">
              <el-image v-for="(item,index) in detailPicList" :key="index" :src="item" fit="cover"
                        :lazy="index>1"></el-image>
            </div>
          </div>
          <shopList ref="child"></shopList>
          <!--					<div class="guess_like">-->
          <!--						<p class="title">猜你喜欢</p>-->
          <!--						<productList :listData="list"></productList>-->
          <!--					</div>-->
        </div>
      </div>
      <shopList ref="child"></shopList>
    </div>
    <myFoot></myFoot>
  </div>
</template>

<script>
import Vue from 'vue';
import "swiper/swiper-bundle.min.css";
import Swiper, {Navigation, Thumbs} from "swiper";
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import productList from '../../components/productList/productlist';
import shopList from '../../components/shopList/shopList';
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "detail",
  data() {
    return {
      is_show: false,
      galleryThumbs: null,
      galleryTop: null,
      infoType: 2,
      product_id: '',
      showPrice: '',
      showNum: "",
      productData: {},
      bannerList: [],
      detailPicList: [],
      skuList: [],
      cateList: [],
      tabId1: '',
      tabId2: '',
      select_sku_ids: '',
      is_select_sku_ids: false,
      product_sku_id: '',
      formatList: [],
      ids_arr: [],
      list: []
    }
  },
  components: {
    myHead, myFoot, productList, shopList
  },
  watch: {
    $route() {
      this.product_id = this.$route.query.id
      this.is_show = false
      this.list=[]
      this.detailPicList=[]
      this.detailPicList=[]
      this.productData={}
      this.init();
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(function () {
      this.product_id = this.$route.query.id

      this.init()


      this.galleryThumbs = new Swiper('.small_list .swiper', {
        spaceBetween: 14,
        slidesPerView: 6,
        loopedSlides: 1, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        on: {
          resize: function () {
            this.init();
          }
        },
      });
      this.galleryTop = new Swiper('.big_show .swiper', {
        loopedSlides: 1, //looped slides should be the same
        thumbs: {
          swiper: this.galleryThumbs,
          autoScrollOffset: 1,
          slideThumbActiveClass: 'red_border',
        },
        navigation: {
          nextEl: '.big_show .sl_btn.next',
          prevEl: '.big_show .sl_btn.prev',
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        on: {
          resize: function () {
            this.init();
          }
        },
        modules: [Thumbs, Navigation],
      });
    })
  },
  methods: {
    ...mapActions({
      getProductDetail: "index/getProductDetail",
      doProductSelect: "index/doProductSelect",
      doSubmitPH: "cart/doSubmitPH",
    }),
    init() {
      let that = this
      // const loading = this.$loading({
      // 	lock: true,
      // 	text: 'Loading',
      // 	spinner: 'el-icon-loading',
      // 	background: 'rgba(255, 255, 255, 0.2)'
      // });
      that.is_show = false
      this.getProductDetail({
        data: {product_id: this.product_id}, success: (res) => {
          // loading.close();
          if (res.code === 200) {
            // console.log('xxxxxxxxxxx',res)
            this.productData = JSON.parse(JSON.stringify(res.data))
            this.showPrice = res.data.discount_price
            this.skuList = JSON.parse(JSON.stringify(res.data.sku))
            this.bannerList = res.data.product_detail.banner_pic
            this.detailPicList = res.data.product_detail.detail_pic
            let spec_arr = JSON.parse(JSON.stringify(res.data.multi_spec.spec_attr))
            let spec_pic = JSON.parse(JSON.stringify(res.data.multi_spec.spec_pic))
            for (let i in spec_arr[0].spec_items) {
              let id = spec_arr[0].spec_items[i].item_id
              for (let key in spec_pic) {
                if (id == key) {
                  spec_arr[0].spec_items[i].icons = spec_pic[key]
                  break
                }
              }
              spec_arr[0].spec_items[i].is_select = false
            }
            if (spec_arr.length > 1) {
              for (let i = 1; i < spec_arr.length; i++) {
                for (let c in spec_arr[i].spec_items) {
                  spec_arr[i].spec_items[c].is_select = false
                }
              }
            }
            this.cateList = spec_arr
            this.ids_arr = new Array(spec_arr.length)
            // this.ids_arr = Array.apply(null, Array(spec_arr.length))
            let flist = res.data.product_detail.product_format_new
            let formatList = []
            for (let key in flist) {
              formatList.push(flist[key][0])
            }
            this.formatList = formatList
            this.list = res.data.products
            this.is_show = true
          } else {
            this.$message({message: res.msg, type: 'error',});
            setTimeout(function () {
              that.$router.go(-1)
            }, 2000)
          }
        }
      })
    },
    configTab(i1, i2) {
      let cateList = this.cateList
      let ids_arr = this.ids_arr
      for (let d in cateList[i1].spec_items) {
        cateList[i1].spec_items[d].is_select = false
      }
      cateList[i1].spec_items[i2].is_select = true
      ids_arr[i1] = cateList[i1].spec_items[i2].item_id
      if (this.check_ids(ids_arr)) {
        this.select_sku_ids = ids_arr.toString().replace(/,/g, '_')
        this.is_select_sku_ids = true
      }
      // if(cateList.length>1){
      // 	if(i1==0){
      // 		this.tabId1 = cateList[0].spec_items[i2].item_id
      // 	}else{
      // 		this.tabId2 = cateList[1].spec_items[i2].item_id
      // 	}
      // 	if(this.tabId1 && this.tabId2){
      // 		this.select_sku_ids = this.tabId1 + '_' + this.tabId2
      // 		this.is_select_sku_ids = true
      // 	}
      // }else{
      // 	this.select_sku_ids = cateList[i1].spec_items[i2].item_id
      // 	this.is_select_sku_ids = true
      // }
      this.cateList = cateList
      if (this.is_select_sku_ids) {
        this.showPrice = this.skuList[this.select_sku_ids].price
        this.showNum = this.skuList[this.select_sku_ids].stock_num
        this.product_sku_id = this.skuList[this.select_sku_ids].product_sku_id
      }

    },
    check_ids(arr) {
      let coun = 0
      for (let i in arr) {
        if (arr[i]) coun += 1
      }
      return coun == arr.length ? true : false
    },
    buyNow() {

      if (!this.is_select_sku_ids) {
        this.$message({message: '请选择商品规格！', type: 'error',});
        return false
      }
      let param = {product_id: this.productData.product_id, product_sku_id: this.product_sku_id}
      this.$router.push({name: 'settlement', query: param})
    },
    bindSelect() {//商品选取
      if (!this.is_select_sku_ids) {
        this.$message({message: '请选择商品规格！', type: 'error',});
        return false
      }
      let param = {product_id: this.productData.product_id, product_sku_id: this.product_sku_id}
      // console.log(param)
      this.doProductSelect({
        data: param, success: (res) => {
          if (res.code === 200) {
            this.$message({message: res.msg, type: 'success',});
          } else {
            this.$message({message: res.msg, type: 'error',});
          }
        }
      })
    },
    callPh() {//打开子组件店铺列表
      // console.log('xcxcxcxc',this.is_select_sku_ids,this.product_sku_id)
      // if(!this.is_select_sku_ids){
      // 	this.$message({message: '请选择商品规格！',type: 'error',});
      // 	return false
      // }
      this.$refs.child.showShopList()
    },
    phSubmit(val, strategy) {//立即铺货
      let param = {
        shop_id: val,
        type: '1',
        product_id: this.productData.product_id,
        product_sku_id: "",
        strategy: strategy
      }
      this.doSubmitPH({
        data: param, success: (res) => {
          if (res.code === 200) {
            this.$message({message: res.msg, type: 'success',});
            setTimeout(() => {
              this.$refs.child.closeShopList()
            }, 300)
          } else {
            this.$message({message: res.msg, type: 'error',});
          }
        }
      })
    },
    gotoSupplier(id) {
      // if(this.userinfo===""){
      //   this.$message.error("请先登录！")
      // }else{
      this.$router.push(`/supplier?id=${id}`)
      // }
    },
  }
}
</script>

<style scoped>
.d-f {
  display: flex;
}

.top_content {
  width: 100%;
  height: auto;
  padding: 26px 0 34px;
  background: #fff;
}

.page_links {
  line-height: 1;
  font-size: 14px;
  color: #999999;
  display: flex;
}

.detail_content {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.detail_content .left_con {
  width: 480px;
  height: auto;
}

.left_con .big_show {
  width: 480px;
  height: 480px;
  position: relative;
}

.left_con .big_show .img_box {
  width: 480px;
  height: 480px;
  overflow: hidden;
}

.left_con .big_show .sl_btn {
  width: 18px;
  height: 58px;
  background: #eee;
  position: absolute;
  bottom: -68px;
  z-index: 22;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: .6;
}

.left_con .big_show .sl_btn.prev {
  left: 0;
}

.left_con .big_show .sl_btn.next {
  right: 0;
}

.left_con .big_show .sl_btn:hover {
  opacity: 1;
}

.left_con .big_show .sl_btn .iconfont {
  font-size: 14px;
  color: #666666;
}

.left_con .big_show .sl_btn.next .iconfont {
  transform: rotate(180deg);
}

.left_con .small_list {
  height: auto;
  margin: 10px 30px;
  position: relative;
}

.left_con .small_list .img_box {
  width: 58px;
  height: 58px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.left_con .small_list .red_border .img_box {
  border-color: #f0423f;
}

.left_con .img_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_con .img_box > img {
  width: auto;
}

.detail_content .right_con {
  width: 690px;
  height: auto;
}

.right_con .title {
  font-size: 18px;
  line-height: 28px;
  color: #232323;
  font-weight: bold;
}

.right_con .price {
  margin-top: 8px;
  color: #f0423f;
  margin-bottom: 5px;
  position: relative;
}

.right_con .sales_num {
  font-size: 12px;
  color: #999;
  margin-left: 5px;
}

.right_con .price > em {
  font-size: 24px;
}

.right_con .price > span {
  margin-left: 12px;
  color: #999999;
  font-size: 16px;
}

.right_con .border_content {
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.right_con .flow {
  max-height: 300px;
  overflow: hidden auto;
  margin-top: 24px;
  padding-bottom: 0;
}

.right_con .txt_line {
  margin-top: 26px;
  display: flex;
  font-size: 14px;
}

.right_con .txt_line .txt {
  width: 78px;
  color: #999999;
}

.right_con .txt_line .con {
  flex: 1;
  width: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.right_con .txt_line .con.curp {
  cursor: pointer;
}

.right_con .txt_line .con .icon {
  width: 14px;
  height: 14px;
  margin-left: 16px;
  margin-right: 5px;
}

.right_con .config_wrap {
  display: flex;
  padding-bottom: 10px;
}

.right_con .config_wrap:last-child {
  padding-bottom: 0;
}

.right_con .config_wrap .txt {
  width: 78px;
  line-height: 44px;
  color: #999999;
}

.right_con .config_wrap .con {
  flex: 1;
  width: 0;
  display: flex;
  flex-wrap: wrap;
}

.right_con .config_wrap .config_box {
  width: auto;
  height: 44px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-size: 12px;
  cursor: pointer;
  padding: 0 8px 0 4px;
  white-space: nowrap;
  min-width: 112px;
}

.right_con .config_wrap:nth-child(1) .config_box {
  justify-content: flex-start;
}

.right_con .config_wrap .config_box:hover {
  background: #f5f5f5;
}

.right_con .config_wrap .config_box.active {
  border-color: #f0423f;
}

.right_con .config_wrap .config_box .img {
  max-width: 36px;
  max-height: 36px;
  margin-right: 6px;
}

.right_con .btn_wrap {
  margin: 30px 12px 0;
  display: flex;
}

.right_con .btn_wrap .btn {
  width: 160px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.right_con .btn_wrap .btn.bgred {
  background: #fa5757;
  color: #fff;
}

.right_con .btn_wrap .btn.bgwhite {
  background: #fff;
  color: #f0423f;
  border: 1px solid #f0423f;
}

.service_hide {
  width: 420px;
  height: auto;
  box-sizing: border-box;
  padding: 20px;
}

.service_hide .title {
  line-height: 1;
  font-weight: bold;
  color: #999999;
  text-align: center;
  position: relative;
  margin-bottom: 4px;
}

.service_hide .title::before {
  content: '';
  width: 120px;
  height: 1px;
  background-image: linear-gradient(to right, rgba(153, 153, 153, 0), rgba(153, 153, 153, 1));
  position: absolute;
  top: 50%;
  left: 23px;
  transform: translateY(-50%);
}

.service_hide .title::after {
  content: '';
  width: 120px;
  height: 1px;
  background-image: linear-gradient(to right, rgba(153, 153, 153, 1), rgba(153, 153, 153, 0));
  position: absolute;
  top: 50%;
  right: 23px;
  transform: translateY(-50%);
}

.service_hide .txt_con {
  margin-top: 16px;
  width: 100%;
  display: flex;
}

.service_hide .txt_con .icon-gouxuan {
  font-size: 14px;
  color: #f0423f;
}

.service_hide .txt_con .con {
  flex: 1;
  width: 0;
  margin-left: 8px;
}

.service_hide .txt_con .con .tit {
  font-size: 14px;
  line-height: 1;
}

.service_hide .txt_con .con .txt {
  margin-top: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}

.main_content {
  padding: 20px 0 40px;
}

.main_content .goods_info {
  height: auto;
  padding: 0 20px 42px;
  background: #fff;
}

.goods_info .tabs_wrap {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
}

.tabs_wrap .tab {
  height: 100%;
  box-sizing: border-box;
  line-height: 1;
  padding-top: 28px;
  position: relative;
  font-weight: bold;
  color: #232323;
  cursor: pointer;
  margin-right: 40px;
}

.tabs_wrap .tab.active {
  color: #f0423f;
}

.tabs_wrap .tab.active::after {
  content: '';
  width: 100%;
  height: 4px;
  background: #f0423f;
  position: absolute;
  left: 0;
  bottom: 0;
}

.goods_info .info_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 30px;
  padding-top: 20px;
}

.info_wrap .info_line {
  width: 33.33%;
  margin-top: 20px;
  font-size: 12px;
  display: flex;
}

.info_wrap .info_line > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info_wrap .info_line > p:nth-child(1) {
  color: #999999;
  width: 35%;
}

.info_wrap .info_line > p:nth-child(2) {
  flex: 1;
  width: 0;
  margin-right: 20px;
}

.goods_info .rich_txt {
  padding-top: 20px;
}

.main_content .guess_like {
  width: 260px;
  padding: 20px 5px 20px 20px;
  box-sizing: border-box;
  background: #fff;
  margin-right: 15px;
}

.guess_like .title {
  line-height: 1;
  font-weight: bold;
  color: #232323;
}
</style>
<style>
.rich_txt .el-image {
  display: flex;
  width: 100%;
}

.page_links .el-breadcrumb__inner {
  font-weight: bold !important;
  color: #333 !important;
}

.page_links .el-breadcrumb__inner.is-link {
  font-weight: initial !important;
  color: #999 !important;
}

.page_links .el-breadcrumb__inner.is-link:hover {
  color: #f0423f !important;
  text-decoration: underline;
}

.service.el-dropdown-menu {
  margin-top: 8px !important;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.service.el-dropdown-menu::-webkit-scrollbar {
  width: 3px;
}

.service.el-dropdown-menu::-webkit-scrollbar-thumb {
  background: #DDDDDD;
  border-radius: 3px;
}

.service.el-dropdown-menu::-webkit-scrollbar-track {
  background: #F2F2F2;
  border-radius: 3px;
}

.service.el-dropdown-menu .popper__arrow {
  display: none !important;
}

.supplier-name {
  color: #000;
  font-weight: bold;
}

.supplier-name:hover {
  color: #fa5757;
  text-decoration: underline;
  cursor: pointer;

}

</style>
